/*!
 * Style CSS pour IE9 et moins
 */

.no-flexbox{
    @import 'foundation';

    :last-child:not(:first-child).column,
    :last-child:not(:first-child).columns{
        float: left;
    }
}
